import { Button, Modal } from "react-bootstrap";
import cautionIcon from "../../../assets/images/caution.svg";
import { toast } from "react-toastify";
// import "./DeleteModal.scss";
import WebService from "../../../Services/WebService";
interface PropsData {
  show: any;
  closeModal: any;
  action: any;
  msg?:any
}

const DeleteModal = (props: PropsData) => {
  const handleDelete = () => {
    

    if (props?.action === "") {
      toast("Record deleted successfully!!", {
        position: toast.POSITION.TOP_RIGHT,
        // className: "toast-message",
      });
      props.closeModal(true);
    } else {
      WebService.addLoader("yes");
      return WebService.deleteAPI({
        action: props?.action,
      })
        .then((res: any) => {
          toast.success(res?.message);
          props.closeModal(true);
          WebService.removeLoader("yes");
          // toast("Record deleted successfully!!", {
          // position: toast.POSITION.TOP_RIGHT,
          // className: "toast-message",
        })
        .catch((error) => {
          WebService.removeLoader("yes");
          toast.error(error?.response?.data?.message);
          console.error("Error fetching countries:", error);
        });
    }
  };
  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => {
          props.closeModal();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="text-center">
            <img src={cautionIcon} width={60} className="mb-3" alt="" />
            <h2 className="font-18 font-semibold">Are you Sure?</h2>
            <p className="text-secondary-dark">{props?.msg ?props?.msg:"You want to delete?"}</p>
            <div className="px-3 py-lg-1 py-3 text-end mobile-sticky-bottom text-center">
              <Button
                className="btn-outline-brand me-3"
                id="no"
                onClick={() => {
                  props.closeModal();
                }}
              >
                No
              </Button>
              <Button
                className="btn-brand"
                id="yes"
                onClick={() => handleDelete()}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeleteModal;
