import moment from "moment";

const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            localStorage.getItem("date_format") + " hh:mm A" ??
              "DD-MM-YYYY  hh:mm A"
          );
      }
    }
  },

  removeUnderScore(str: any) {
    if ((str !== "" || str !== undefined) && str.includes("_")) {
      return this.titleCase(str.replace(/_/g, " "));
    } else {
      return this.titleCase(str);
    }
  },

  toProperSeparatedString(a: any) {
    return a.join(", ").replace(/,(?!.*,)/gim, " and");
  },

  getFormattedCurrency(amount: any) {
    if (amount === "" || amount === undefined) {
      return "";
    }
    
    if (!amount) {
      return "";
    }
    var fractionDigit = 0;
    if (amount % 1 > 0) {
      fractionDigit = 2;
    }
    var inr = Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigit,
    }).format(amount);
    return inr;
  },

  toProperCase(str: string) {
    if (!str) {
      return "";
    }
    const pattern = /_/gi;
    const replacement = " ";
    const result = str.replaceAll(pattern, replacement);

    return result.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  validateMobileNumber(number: any) {
    if (number && !/^[0-9]{10}$/.test(number)) {
      return "Please enter valid contact number";
    }
    return true;
  },

  checkEmptyUndefinedNull(value: any) {
    if (value !== null && value !== undefined && value != "") {
      return true;
    }
    return false;
  },

  titleCase(str: any) {
    if (str !== "") {
      return str
        .toLowerCase()
        .split(" ")
        .map((word: any) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    } else {
      return str;
    }
  },
  removeDialCodeFromMobile(mobileNumber: any, dialCode: any) {
    if (
      typeof mobileNumber === "string" &&
      typeof dialCode === "string" &&
      dialCode.length > 0
    ) {
      if (mobileNumber.startsWith(dialCode)) {
        return mobileNumber.slice(dialCode.length);
      }
    }
    return mobileNumber;
  },
  getFormatedStatusText(dt: string) {
    if (dt !== "" && dt !== undefined) {
      dt = dt.replaceAll("_", " ");
      dt = dt[0].toUpperCase() + dt.slice(1).toLowerCase();
      return dt;
    } else {
      return "";
    }
  },

  validateAadhaar(aadhaarNumber: any) {
    const regex = /^\d{12}$/;
    return regex.test(aadhaarNumber);
  },
  validateEmail(email: any) {
    

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return regex.test(email);
  },
  checkLegalBirthDate(birthDate: any) {
    const currentDate: any = moment();
    const birthdate = moment(birthDate);
    const yearsDiff = currentDate.diff(birthdate, "years");
    if (yearsDiff >= 18) {
      return true;
    } else {
      return false;
    }
  },
  validatePAN(pan: any) {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return regex.test(pan);
  },

  toUpperCase(str: any) {
    return str.toUpperCase();
  },
  getDivHeight(id: any) {
    let div = document.getElementById(id);
    if (div) {
      return div.offsetHeight;
    }
  },
  getFormattedDate(dt: any) {
    if (dt) {
      return moment(dt).format("DD-MMM-YYYY");
    }
  },
  getAdminFormattedDate(dt: any) {
    if (dt) {
      return moment(dt).format("DD.M.YYYY");
    }
  },

  //PINCODE
  //   onKeyDown={(e) => {
  //     if (!/^\d$/.test(e.key) && e.key.length === 1 && !e.ctrlKey && !e.metaKey) {
  //         e.preventDefault();
  //     }
  // }}

  // const isValidInput = /^\d+$/.test(value);
  // if (!isValidInput) {
  //   return;
  // }

  //PAN validation
  // pattern: {
  //   value: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
  //   message: "Invalid PAN ",
  // },
};

export default HelperService;
