import { useEffect, useState } from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../../assets/images/calendar-icon.svg";
import WebService from "../../Services/WebService";
import Select from "react-dropdown-select";
import HelperService from "../../Services/HelperService";
interface PropData {
  setFilter?: any;
  property_type?: any;
  property_Id?: any;
  load?: any;
  showFilter?: any;
}
const status = [
  { key: "Open", value: "OPEN" },
 
  { key: "Close", value: "CLOSE" },
];
const FilterServiceRequest = (props: PropData) => {
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [buildingOptionLoader, setBuildingOptionLoader] = useState(false);
  const [buildingOptions, setBuildingOptions] = useState<any[]>([]);
  const [unitLoader, setUnitLoader] = useState(false);
  const [unitOptions, setUnitOptions] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState({
    category_id: { value: "", label: "" },
    date: "",
    building_id: { value: "", label: "" },
    unit_number: { value: "", label: "" },
    priority: "",
    status: "",
  });
  useEffect(() => {
    getCategories();
    if (props?.property_type === "MULTIPLE_UNIT") {
      getBuildingOptions("");
    } else if (props?.property_type === "SINGLE_UNIT") {
      getUnitDetails_SingleUnit();
    }
  }, [props?.load]);

  useEffect(() => {
    if (props?.showFilter) {
      setFilterObject({
        category_id: { value: "", label: "" },
        date: "",
        building_id: { value: "", label: "" },
        unit_number: { value: "", label: "" },
        priority: "",
        status: "",
      });
    }
  }, [props?.showFilter]);
  const getCategories = () => {
    setCategoryLoader(true);
    return WebService.getAPI({
      action: `sub-category-list`,
    })
      .then((res: any) => {
        const updatedOptions = res.list.map((subCategory: any) => ({
          value: subCategory.id,
          label: subCategory.title,
        }));
        setCategoryOptions(updatedOptions);
        setCategoryLoader(false);
      })
      .catch((error) => {
        setCategoryLoader(false);
        console.error("Error fetching data:", error);
      });
  };
  const getBuildingOptions = (inputValue: any) => {
    setBuildingOptionLoader(true);
    return WebService.getAPI({
      action: `draw/property/${props?.property_Id}/building`,
    })
      .then((res: any) => {
        if (res?.info?.buildings.length > 0) {
          const updatedOptions = res.info.buildings.map((building: any) => ({
            value: building.id,
            label: building.building_name,
            uuid: building.uuid,
          }));

          setBuildingOptions(updatedOptions);
        }
        if (res?.list?.length === 1) {
          setFilterObject((prev: any) => ({
            ...prev,
            building_id: {
              value: res?.list[0]?.id,
              label: res?.list[0]?.building_name,
            },
          }));
        }

        setBuildingOptionLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBuildingOptions([]);
        setBuildingOptionLoader(false);
      });
  };
  const handleApplyFilter = () => {
    let obj = {
      category_id: filterObject?.category_id?.value,
      date: filterObject?.date,
      building_id: filterObject?.building_id?.value,
      unit_number: filterObject?.unit_number?.value,
      priority: filterObject?.priority,
      status: filterObject?.status,
    };
    props?.setFilter(obj);
  };
  const getUnitDetails_SingleUnit = () => {
    setUnitLoader(true);
    return WebService.getAPI({
      action: `unit-dropdown/${props?.property_Id}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((unit: any) => ({
            value: unit?.id,
            label: HelperService?.removeUnderScore(unit?.title ?? ""),
            unit_id: unit?.unit_id,
          }));
          setUnitOptions(updatedOptions);
        } else {
          setUnitOptions([]);
        }
        setUnitLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setUnitOptions([]);
        setUnitLoader(false);
      });
  };
  const getUnitDetailsForMulti = (building_id: any) => {
    setUnitLoader(true);
    return WebService.getAPI({
      action: `floor/unit/dropdown/${building_id}`,
    })
      .then((res: any) => {
        if (res.list.length > 0) {
          const updatedOptions = res.list.map((unit: any) => ({
            value: unit?.id,
            label: unit?.room_no,
            unit_id: unit?.unit_id,
          }));
          setUnitOptions(updatedOptions);
          if (res?.list?.length === 1) {
            setFilterObject((prev: any) => ({
              ...prev,
              unit_id: {
                value: res?.list[0]?.id,
                label: res?.list[0]?.room_no,
                unit_id: res?.list[0]?.unit_id,
              },
            }));
          }
        } else {
          setUnitOptions([]);
        }
        setUnitLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBuildingOptions([]);
        setUnitLoader(false);
      });
  };
  const handleClear = () => {
    props.setFilter({
      category_id: "",
      date: "",
      building_id: "",
      unit_number: "",
      priority: "",
      status: "",
    });
    // setFilterObject({
    //   category_id: { value: "", label: "" },
    //   date: null,
    //   building_id: { value: "", label: "" },
    //   unit_number: { value: "", label: "" },
    //   priority: "",
    //   status: "",
    // });
  };
  return (
    <>
      <div className="property-filter">
        <div className="filter-title d-none d-lg-inline">Filter</div>
        <Dropdown.Divider />

        <div className="px-lg-3 form-style filter-body">
          <div className="mb-3">
            <label className="form-label d-block">Date</label>

            <input
              type="date"
              id="dob"
              className="form-control"
              max={new Date().toISOString().split("T")[0]}
              style={{ fontSize: "14px" }}
              placeholder="Select date of birth"
              value={filterObject?.date || ""}
              onChange={(e: any) => {
                setFilterObject((prev: any) => ({
                  ...prev,
                  date: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label required">Catagory</label>
            <Select
              placeholder="Select Category"
              loading={categoryLoader}
              values={
                filterObject?.category_id?.label === ""
                  ? []
                  : [filterObject?.category_id]
              }
              options={categoryOptions}
              labelField="label"
              valueField="value"
              onChange={(values: any) => {
                if (values.length > 0) {
                  // setSelectBuildingErrMsg("");
                  setFilterObject((prev: any) => ({
                    ...prev,
                    category_id: values[0],
                  }));
                }
              }}
            />
          </div>
          {props?.property_type === "MULTIPLE_UNIT" ? (
            <div className="mb-3">
              <label className="form-label">Building</label>
              <Select
                placeholder="Select Building"
                loading={buildingOptionLoader}
                values={
                  filterObject?.building_id?.label === ""
                    ? []
                    : [filterObject?.building_id]
                }
                options={buildingOptions}
                labelField="label"
                valueField="value"
                onChange={(values: any) => {
                  if (values.length > 0) {
                    setFilterObject((prev: any) => ({
                      ...prev,
                      building_id: values[0],
                      unit_number: { value: "", label: "" },
                    }));
                    getUnitDetailsForMulti(values[0].value);
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}

          {/* <div className="mb-3">
            <label className="form-label">Unit Number</label>
            <Select
              placeholder="Select Unit"
              loading={unitLoader}
              values={
                filterObject?.unit_number?.label === ""
                  ? []
                  : [filterObject?.unit_number]
              }
              options={unitOptions}
              labelField="label"
              valueField="value"
              onChange={(values: any) => {
                if (values.length > 0) {
                  setFilterObject((prev: any) => ({
                    ...prev,
                    unit_number: values[0],
                  }));
                }
              }}
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label d-block">Priority</label>
            <div className="btn-group chekbox-btns" role="group">
              <input
                type="checkbox"
                className="btn-check"
                id="High"
                value="High"
                checked={filterObject?.priority === "High"}
                onChange={(e: any) => {
                  setFilterObject((prev: any) => ({
                    ...prev,
                    priority: e.target.value,
                  }));
                }}
              />
              <label className="btn btn-outline-light" htmlFor="High">
                High
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Normal"
                value="Medium"
                checked={filterObject?.priority === "Medium"}
                onChange={(e: any) => {
                  setFilterObject((prev: any) => ({
                    ...prev,
                    priority: e.target.value,
                  }));
                }}
              />
              <label className="btn btn-outline-light" htmlFor="Normal">
                Medium
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Low"
                value="Low"
                checked={filterObject?.priority === "Low"}
                onChange={(e: any) => {
                  setFilterObject((prev: any) => ({
                    ...prev,
                    priority: e.target.value,
                  }));
                }}
              />
              <label className="btn btn-outline-light" htmlFor="Low">
                Low
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label d-block">Status</label>
            <div
              className="btn-group chekbox-btns flex-wrap gap-2"
              role="group"
            >
              {status.map((statusOption: any, index: any) => {
                return (
                  <div className="py-1">
                    <input
                      type="checkbox"
                      className="btn-check"
                      name={"status"}
                      value={statusOption?.value}
                      id={`check_${index}`}
                      checked={statusOption?.value === filterObject?.status}
                      onChange={(e: any) => {
                        setFilterObject((prev: any) => ({
                          ...prev,
                          status: e.target.value,
                        }));
                      }}
                    />
                    <label
                      className="btn btn-outline-light"
                      htmlFor={`check_${index}`}
                    >
                      {statusOption.key}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Dropdown.Divider />
        <div className="px-3 py-lg-1 py-3 text-end mobile-sticky-bottom">
          <Button
            className="btn-outline-brand me-3"
            onClick={() => {
              handleClear();
            }}
          >
            Clear
          </Button>
          <Button
            className="btn-brand"
            onClick={() => {
              handleApplyFilter();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
export default FilterServiceRequest;
